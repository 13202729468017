<template>
  <div class="">
    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :toolbars="toolbars"
      :options.sync="options">

      <template slot="option" v-if="options.toolbar === 'other'">
        <div class="mb-3">
          <DetailOrderProfileInput
            placeholder="รายการเงินได้..."
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :contactId="formData.contactId"
            :profileTypes="incomeTypes">
          </DetailOrderProfileInput>

          <DetailOrderProfileInput
            placeholder="รายการหัก..."
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :contactId="formData.contactId"
            :profileTypes="defectTypes">
          </DetailOrderProfileInput>

          <DetailOrderSocialSecurityInput
            ref="socialSecurityInput"
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :contactId="formData.contactId">
          </DetailOrderSocialSecurityInput>

          <DetailOrderTaxInput
            ref="taxInput"
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :contactId="formData.contactId">
          </DetailOrderTaxInput>
        </div>
      </template>

      <template slot-scope="row">
        <template v-if="row.item.type === 'netPrice'">
          <tr>
            <td v-if="row.hidden.name">ยอดรวม</td>
            <td v-if="row.hidden.qty"></td>
            <td v-if="row.hidden.price"></td>
            <td v-if="row.hidden.totalPrice"></td>
          </tr>
        </template>

        <DetailOrderSubItem
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :row="row"
          v-model="selectedRows"
          :canDestroy="!formData.approvedAt">
        </DetailOrderSubItem>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import { round } from 'lodash'
import DetailOrderProfileInput from './DetailOrderProfileInput.vue'
import DetailOrderSocialSecurityInput from './DetailOrderSocialSecurityInput.vue'
import DetailOrderTaxInput from './DetailOrderTaxInput.vue'
import DetailOrderSubItem from './DetailOrderSubItem.vue'
import {
  LIST_ORDER,
  WATCH_ORDER_CREATED,
  WATCH_ORDER_UPDATED,
  WATCH_ORDER_DESTROYED,
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name', 'qty', 'price', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      orders: [],
      selectedRows: [],
      itemTypes: [
        'salary', 'ot', 'duty',
        'welfare', 'premium', 'otherIncome',
        'defectDuty', 'defectWelfare', 'defectIncome',
        'studentLoan', 'socialSecurity', 'tax',
        'netPrice'
      ],
      incomeTypes: [
        {text: 'เงินเดือน', value: 'salary'},
        {text: 'เงินล่วงเวลา', value: 'ot'},
        {text: 'เงินได้หน้าที่', value: 'duty'},
        {text: 'สวัสดิการ', value: 'welfare'},
        {text: 'เงินรางวัล', value: 'premium'},
        {text: 'เงินได้อื่นๆ', value: 'otherIncome'},
      ],
      defectTypes: [
        {text: 'หักบกพร่อง', value: 'defectDuty'},
        {text: 'หักสวัสดิการ', value: 'defectWelfare'},
        {text: 'หักกยศ', value: 'studentLoan'},
        {text: 'หักอื่นๆ', value: 'defectIncome'},
      ]
    }
  },
  computed: {
    headers () {
      return [
        {text: 'รายละเอียด', value: 'name'},
        {text: 'จำนวน', value: 'qty', class: ['text-right']},
        {text: 'จำนวน/หน่วย', value: 'price', class: ['text-right']},
        {text: `ราคา (${this.ledgersSum})`, value: 'totalPrice', class: ['text-right']}
      ]
    },
    toolbars () {
      if (this.formData.approvedAt) return []

      const arr = [
        {value: 'other', icon: 'plus', class: 'text-success'}
      ]

      return arr
    },
    isEditable () {
      return this.formData.approvedAt && !this.formData.closedAt
    },
    items () {
      return [
        ...this.itemTypes.flatMap(itemType => {
          return this.orders.filter(v => v.type === itemType)
        })
      ]
    },
    ledgersSum () {
      const total = this.orders
      .flatMap(v => v.ledgers)
      .reduce((t,v) => t += +v.amount, 0)

      return round(total, 2)
    },
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'network-only',
      subscribeToMore: [
        {
          document () {
            return WATCH_ORDER_CREATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const newOrder = subscriptionData.data.orderCreated
            const orders = [...previous.orders, newOrder]
            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_UPDATED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const updatedOrder = subscriptionData.data.orderUpdated
            const orders = [...previous.orders]
            const idx = orders.findIndex(v => v.id === updatedOrder.id)
            if (idx !== -1) {
              orders[idx] = updatedOrder
            }
            return {...previous, orders}
          }
        },
        {
          document () {
            return WATCH_ORDER_DESTROYED(this.templateType)
          },
          variables() {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const destroyedOrder = subscriptionData.data.orderDestroyed
            const orders = [...previous.orders]
            const idx = orders.findIndex(v => v.id === destroyedOrder.id)
            if (idx !== -1) {
              orders.splice(idx, 1)
            }
            return {...previous, orders}
          }
        }
      ]
    }
  },
  watch: {
    'formData.approvedAt': {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.options.toolbar = ''
        }
      }
    },
    orders () {
      this.$refs?.socialSecurityInput?.calculatePrice()
      this.$refs?.taxInput?.calculatePrice()
    }
  },
  components: {
    DetailOrderSubItem,
    DetailOrderProfileInput,
    DetailOrderSocialSecurityInput,
    DetailOrderTaxInput
  }
}
</script>

<style lang="css">
</style>
