<template>
  <span class="">
    <button
      class="btn btn-info"
      @click="toggle">
      <fa icon="calculator"></fa>
    </button>

    <b-modal
      v-model="showModal"
      :title="title"
      hide-footer
      body-class="p-0">

      <div class="row px-3">
        <div class="col-12">
          <ul class="list-unstyled">
            <li
              v-for="res in items"
              :key="res.docId"
              class="mt-3">
              <div>************* {{res.date | date}} {{res.docCode}} **************</div>
              <div>1. เงินเดือน: {{res.salary | comma}}</div>
              <div>2. สัดส่วนเข้างานจริง: {{res.factor | comma}}</div>
              <div>3. รายได้ (ปี): {{res.income | comma}}</div>
              <div>4. รายอื่นๆ: {{res.otherIncome | comma}} ({{res.accOtherIncome | comma}})</div>
              <div>5. หักค่าใช้จ่าย: {{res.discount | comma}}</div>
              <div>6. หักประกันสังคม: {{res.socialSecurity | comma}}</div>
              <div>7. รายได้สุทธิ: {{res.baseIncome | comma}}</div>
              <div>8. รายได้สุทธิ + รายอื่นๆ - หักอื่นๆ: {{res.netIncome | comma}}</div>
              <div>9. ภาษีฐานเงินเดือนปกติ (ปี): {{res.baseIncomeTax | comma}}</div>
              <div>10. ภาษีรายได้เดือนปัจจุบัน (ปี): {{res.netIncomeTax | comma}}</div>
              <div>11. ภาษีรายได้เดือนที่ผ่านมา (ปี): {{res.prevIncomeTax | comma}}</div>
              <div>12. ภาษีฐานเงินเดือนปกติ (9) / 12 (เดือน): {{res.baseIncomeTaxPerMonth | comma}}</div>
              <div>13. (12) x (2): {{res.baseIncomeTaxPerMonthWithFactor | comma}}</div>
              <div>14. ส่วนต่าง (10) - (11): {{res.deltaIncomeTax | comma}}</div>
              <div>15. รวม (13) + (14): {{res.netPrice | comma}}</div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: false,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    toggle () {
      this.showModal = true
    }
  }
}
</script>

<style lang="css" scoped>
</style>
