<template>
  <div class="form-row" v-if="items.length > 0">
    <sgv-input-select
      placeholder="เลือกรายการ..."
      class="col-5 col-md-6"
      :options="items"
      select="id"
      v-model="formData.docConfigId"
      :validations="[
        {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
      ]">
      <template slot-scope="option">
        {{option.name}}
      </template>
    </sgv-input-select>

    <sgv-input-text
      class="col"
      placeholder="จำนวน ราคา"
      v-model="formData.price"
      :validations="[
        {text: 'required!', value: $v.formData.price.$dirty && $v.formData.price.$error}
      ]"
      @keyup.enter.native="createOrder">
    </sgv-input-text>

    <div class="col-auto">
      <button class="btn btn-success" @click="createOrder">
        <fa icon="plus"></fa>
      </button>
    </div>
  </div>
</template>

<script>
import round from 'lodash/round'
import { required } from 'vuelidate/lib/validators'
import {
  LIST_ORDER_SOCIAL_SECURITY,
  CREATE_ORDER_SOCIAL_SECURITY,
  DETAIL_ORDER_SOCIAL_SECURITY_PRICE
} from './graph'

export default {
  name: 'DetailOrderSocialSecurityInput',
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    contactId: {
      type: Number,
      required: true
    },
  },
  data () {
    return {
      formData: {
        docConfigId: null,
        price: ''
      },
      items: []
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_ORDER_SOCIAL_SECURITY(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'network-only',
      result (res) {
        if (res.data?.items.length === 1) {
          this.formData.docConfigId = res.data.items[0].id
        }
      }
    },
  },
  validations: {
    formData: {
      docConfigId: { required },
      price: { required },
    }
  },
  methods: {
    serializeInput (input) {
      try {
        const obj = {docConfigId: input.docConfigId}
        const arr = input.price.split(' ')
        if (arr.length === 1) {
          obj.qty = 1
          obj.price = Number(arr[0])
        } else if (arr.length === 2) {
          obj.qty = Number(arr[0])
          obj.price = Number(arr[1])
        }
        return obj
      } catch (err) {
        this.$toasted.global.error('กรอกข้อมูลไม่ถูกต้อง')
      }
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_ORDER_SOCIAL_SECURITY(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.$v.formData.$reset()

      this.formData.docConfigId = null
      this.formData.price = ''
    },
    calculatePrice () {
      if (!this.formData.docConfigId) {
        this.formData.price = ''
        return
      }

      this.$apollo.query({
        query: DETAIL_ORDER_SOCIAL_SECURITY_PRICE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          docConfigId: this.formData.docConfigId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const {qty, price} = res.data.item
        this.formData.price = `${qty} ${round(price * -1, 2)}`
      })
      .catch(this.$toasted.global.error)
    }
  },
  watch: {
    'formData.docConfigId': {
      handler (v) {
        if (v) {
          const item = this.items.find(x => x.id == v)
          if (item) {
            this.calculatePrice()
          }
        } else {
          this.formData.price = ''
          this.$v.formData.$reset()
        }
      }
    }
  },
  created () {
    this.setDefault()
  }
}
</script>

<style lang="css" scoped>
</style>
