<template>
  <span class="">
    <fa
      icon="pencil-alt"
      :class="{
        'text-warning': !disabled,
        'text-muted': disabled
      }"
      class="pointer mr-1"
      @click="toggle">
    </fa>

    <b-modal
      v-model="showModal"
      :title="title"
      hide-footer
      body-class="p-0">

      <div class="row px-3">
        <div class="col-12 pt-3">
          <div class="form-row">
            <sgv-input-number
              class="col-12"
              label="จำนวน"
              :precision="2"
              minus
              v-model="qty"
              @keyup.enter.native="updateOrder">
            </sgv-input-number>
          </div>

          <button
            type="button"
            class="btn btn-success mb-3"
            @click="updateOrder">
            เพิ่ม
          </button>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>
import { UPDATE_ORDER } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    orderId: {
      type: Number,
      required: true
    },
    orderType: {
      type: String,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      showModal: false,
      qty: this.value
    }
  },
  methods: {
    toggle () {
      if (this.disabled) return
      this.showModal = true
    },
    updateOrder () {
      this.$apollo.mutate({
        mutation: UPDATE_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: this.orderId,
          qty: this.qty
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.showModal = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
