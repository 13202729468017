<template>
  <tr
    v-show="!item.parentId || selectedRow"
    @dblclick.stop="emitInput(item)"
    class="pointer">
    <td v-if="hidden.name">
      <span v-for="ledger in item.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.approvedAt, 'text-warning': !ledger.approvedAt}">
          {{ledger.account.code}}: {{ledger.account.name}}
        </small>
        <br>
      </span>
      <span
        v-show="!item.parentId"
        style="white-space: pre-line;">
        <fa
          v-if="selectedRow === item.id && canDestroy"
          icon="trash"
          class="text-danger pointer"
          @click="destroyOrder(item.id)">
        </fa>
        {{item.name}}
      </span>
      <div v-if="item.inventory">
        <small class="text-primary">
          {{item.inventory.code}} ({{item.inventory.name}})
        </small>
      </div>
      <div
        v-for="child in item.children"
        :key="child.id"
        class="text-info">
        <small>
          <router-link
            class="text-decoration-none text-info"
            :to="toDoc(child.doc)">
            {{child.doc.code}}
          </router-link>
        </small>
      </div>
    </td>
    <td v-if="hidden.qty" class="text-right">
      <span v-for="ledger in item.ledgers" :key="ledger.id"><br></span>
      <span :class="{'text-success': item.qty > 0, 'text-danger': item.qty < 0}">
        {{item.qty | comma(-1)}}
        {{item.unit.name}}
      </span>
      <DetailOrderUpdateModal
        v-if="canDestroy"
        :disabled="item.price < 0"
        class="ml-1"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :orderId="item.id"
        :orderType="item.type"
        :title="item.name"
        :value="item.qty">
      </DetailOrderUpdateModal>
    </td>
    <td
      v-if="hidden.price"
      class="text-right">
      <span v-for="ledger in item.ledgers" :key="ledger.id"><br></span>
      <span v-if="item.price < 0">-</span>
      <span v-else>{{item.price | comma }}</span>
    </td>
    <td v-if="hidden.totalPrice" class="text-right">
      <span v-for="ledger in item.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.amount > 0, 'text-danger': ledger.amount < 0}">
          {{Math.abs(ledger.amount) | comma}}
        </small>
        <br>
      </span>
      <span :class="{'text-success': item.qty > 0, 'text-danger': item.qty < 0}">
        {{item.totalPrice | comma}}
      </span>
    </td>
  </tr>
</template>

<script>
import DetailOrderUpdateModal from './DetailOrderUpdateModal'
import {
  DESTROY_ORDER
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    canDestroy: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    item () {
      return this.row.item
    },
    hidden () {
      return this.row.hidden
    },
    selectedRow () {
      return this.value.find(v => v === this.item.parentId || v === this.item.id)
    }
  },
  methods: {
    emitInput (item) {
      if (item.parentId) return
      const found = this.value.indexOf(item.id)
      const arr = [...this.value]
      if (found === -1) {
        arr.push(item.id)
      } else {
        arr.splice(found, 1)
      }
      this.$emit('input', arr)
    },
    destroyOrder (id) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: id
        }
      })
      .then(() => {
        this.emitOrderUpdated()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitOrderUpdated () {
      this.$emit('updated', null)
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id}
      }
    },
  },
  components: {
    DetailOrderUpdateModal
  }
}
</script>

<style lang="css" scoped>
</style>
